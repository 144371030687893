import React, {useState} from "react";
import Button from "react-bootstrap/Button";
import LoginForm from "../providers/auth/loginForm";

const LoginButton = () => {
    const [showLogin, setShowLogin] = useState(false);

    return (
        <div>
            <Button variant="outline-light" onClick={() => setShowLogin(true)}>
                Log In
            </Button>
            <LoginForm  show={showLogin} onHideClicked={() => {setShowLogin(false);}}/>
        </div>
    );
};

export default LoginButton;
