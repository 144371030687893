import React from "react";
import Button from "react-bootstrap/Button";
import {AuthContext} from "../providers/authProvider";

const LogoutButton = () => {
    const authContext = React.useContext(AuthContext);

    return (
        <Button
            variant="outline-warning"
            onClick={() => authContext.logout()}
        >
            Log Out
        </Button>
    );
};

export default LogoutButton;
