import React from 'react';
import {useLocalObservable} from 'mobx-react';
import {runInAction} from 'mobx';
import walletServer from '../api/walletServer';


export const WalletContext = React.createContext();


const WalletProvider = ({ children }) => {
    const wallet = useLocalObservable(()=>({
        token: null,
        // **ACCOUNTS**
        /*
        {
            Name: "bla-bla",
            Infromation: "bla-bla"
        }
         */
        accounts: [],
        addAccount: async (account) => {
            let walletId = -1;
            try {
                const serverResponse = await walletServer.post(
                    "/account",
                    account,
                    {
                        headers: {
                            Authorization: `Bearer ${wallet.token}`,
                        },
                    }
                );
                walletId = serverResponse.data.InsertedID;
            } catch (err) {
                console.error(err);
            }
            runInAction(() => {
                wallet.accounts.push({...account, Id: walletId});
            });


        },
        removeAccount: async (accountId) => {
            var indx = wallet.accounts.findIndex((el) => el.Id === accountId);
            console.log(`Will delete ${indx}`);
            if (indx === -1) {
                return;
            }
            try {
                await walletServer.delete(
                    `/account/${accountId}`,
                    {
                        headers: { Authorization: `Bearer ${wallet.token}` },
                    }
                );
            } catch (err) {
                console.log(err);
                return;
            }
            runInAction(() => {
                wallet.accounts.splice(indx, 1);
            });
        },
        updateAccount: async (account) => {
            var indx = wallet.accounts.findIndex((el) => el.Id === account.Id);
            if (indx === -1) {
                return;
            }
            try {
                await walletServer.put(
                    `/account/${account.Id}`,
                    account,
                    {
                        headers: {Authorization: `Bearer ${wallet.token}`}
                    }
                );
            } catch (err) {
                console.log(err);
                return;
            }

            runInAction(()=>{
                wallet.accounts[indx] = account;
            });
        },
        fetchAllAccounts: async () => {

            try {
                const serverResponse = await walletServer.get("/accounts", {
                    headers: { Authorization: `Bearer ${wallet.token}` },
                });
                runInAction(() => {
                    wallet.accounts = serverResponse.data ? serverResponse.data : [];
                });

            } catch (err) {
                console.error(err);
            }
        },
        getAccountName: (accountId) => {
            if (!accountId || accountId === undefined) {
                return "";
            }
            let found = wallet.accounts.find((el) => el.Id === accountId);
            if (!found || found === undefined) {
                return "<<"+accountId+">>";
            }
            return found.Name;
        },
        // **MOVEMENTS**
        movements: [],
        addMovement: async (movement) => {
            let movementId = -1;
            try {
                const serverResponse = await walletServer.post(
                    "/movement",
                    movement,
                    {
                        headers: {
                            Authorization: `Bearer ${wallet.token}`,
                        },
                    }
                );
                movementId = serverResponse.data.InsertedID;
            } catch (err) {
                console.error(err);
            }
            runInAction(() => {
                wallet.movements.push({...movement, Id: movementId});
            })
        },
        removeMovement: async (movementId) => {
            var indx = wallet.movements.findIndex((el) => el.Id === movementId);
            console.log(`Will delete ${indx}`);
            if (indx === -1) {
                return;
            }
            try {
                await walletServer.delete(
                    `/movement/${movementId}`,
                    {
                        headers: { Authorization: `Bearer ${wallet.token}` },
                    }
                );
            } catch (err) {
                console.log(err);
                return;
            }
            runInAction(() => {
                wallet.movements.splice(indx, 1);
            });
        },
        updateMovement: async (movement) => {
            var indx = wallet.movements.findIndex((el) => el.Id === movement.Id);
            if (indx === -1) {
                return;
            }
            try {
                await walletServer.put(
                    `/movement/${movement.Id}`,
                    movement,
                    {
                        headers: {Authorization: `Bearer ${wallet.token}`}
                    }
                );
            } catch (err) {
                console.log(err);
                return;
            }

            runInAction(()=>{
                wallet.movements[indx] = movement;
            });
        },
        fetchAllMovements: async () => {
            try {
                const serverResponse = await walletServer.get("/movements", {
                    headers: { Authorization: `Bearer ${wallet.token}` },
                });

                runInAction(() => {
                    wallet.movements = serverResponse.data ? serverResponse.data : [];
                    console.log(`Movements lenght = ${wallet.movements.length}`)
                });



            } catch (err) {
                console.error(err);
            }
        },

        // **PLANS**
        /*
        [
            {
                "Amount": 0,
                "EndDate": 0,
                "Id": "string",
                "StartDate": 0,
                "UserID": "string"
            }
        ]
        */
        plans: [],
        addPlan: async (plan) => {
            let planId = -1;
            try {
                const serverResponse = await walletServer.post(
                    "/plan",
                    plan,
                    {
                        headers: {
                            Authorization: `Bearer ${wallet.token}`,
                        },
                    }
                );
                planId = serverResponse.data.InsertedID;
            } catch (err) {
                console.error(err);
            }
            runInAction(() => {
                wallet.plans.push({...plan, Id: planId});
            })
        },
        removePlan: async (planId) => {
            var indx = wallet.plans.findIndex((el) => el.Id === planId);
            console.log(`Will delete ${indx}`);
            if (indx === -1) {
                return;
            }
            try {
                await walletServer.delete(
                    `/plan/${planId}`,
                    {
                        headers: { Authorization: `Bearer ${wallet.token}` },
                    }
                );
            } catch (err) {
                console.log(err);
                return;
            }
            runInAction(() => {
                wallet.plans.splice(indx, 1);
            });
        },
        updatePlan: async (plan) => {
            var indx = wallet.plans.findIndex((el) => el.Id === plan.Id);
            if (indx === -1) {
                return;
            }
            try {
                await walletServer.put(
                    `/plan/${plan.Id}`,
                    plan,
                    {
                        headers: {Authorization: `Bearer ${wallet.token}`}
                    }
                );
            } catch (err) {
                console.log(err);
                return;
            }

            runInAction(()=>{
                wallet.plans[indx] = plan;
            });
        },
        fetchAllPlans: async () => {
            try {
                const serverResponse = await walletServer.get("/plans", {
                    headers: { Authorization: `Bearer ${wallet.token}` },
                });

                runInAction(() => {
                    wallet.plans = serverResponse.data ? serverResponse.data : [];
                });

            } catch (err) {
                console.error(err);
            }
        },
        getPlanName: (planId) => {
            if (!planId || planId === undefined) {
                return "";
            }
            let found = wallet.plans.find((el) => el.Id === planId);
            if (!found || found === undefined) {
                return "<<"+planId+">>";
            }
            return found.Category;
        }
    }));

    return (
        <WalletContext.Provider value={wallet} >{children}</WalletContext.Provider>
    );
};

export default WalletProvider;
