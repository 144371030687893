import React from 'react';
import './App.css';
import MainArea from './components/MainArea';
import NavigationBar from './components/NavigationBar';
import WalletProvider from './providers/walletProvider';
import ServerAuthProvider from './providers/authProvider';

function App() {
  return (
    <ServerAuthProvider>
      <WalletProvider>
        <>
          <NavigationBar />
          <MainArea styles="{height: 100%, width: 100%}"/>
        </>
      </WalletProvider>
    </ServerAuthProvider>
  );
}

export default App;
