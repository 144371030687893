import React, { useState } from 'react';
import { observer } from "mobx-react";
import Modal from "react-bootstrap/Modal";
import { Container } from "react-bootstrap";
import { AuthContext } from "../authProvider";

const LoginForm = observer(({ show, onHideClicked }) => {
    const authContext = React.useContext(AuthContext);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [passwordConfirmation, setPasswordConfirmation] = useState(''); // New state variable
    const [isLoginMode, setIsLoginMode] = useState(true);
    const [error, setError] = useState(null);

    const toggleMode = () => setIsLoginMode(!isLoginMode);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError(null);

        // Check if the password and password confirmation match
        if (isLoginMode || password === passwordConfirmation) {
            if (await authContext.handleSubmit(email, password, isLoginMode)) {
                onHideClicked();
            } else {
                console.warn('Wrong credentials');
                setError('Wrong credentials');
            }
        } else {
            setError('Password and password confirmation do not match.');
        }
    };

    return (
        <Modal show={show} size='lg' scrollable>
            <Modal.Header closeButton onHide={() => onHideClicked()}>
                <Modal.Title>{isLoginMode ? 'Sign-in' : 'Sign-up'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container>
                    <div>
                        {error ? <div style={{ color: 'red' }}>{error}</div> : null}
                        <form onSubmit={handleSubmit} className="mt-4">
                            <div className="mb-3">
                                <input
                                    className="form-control"
                                    type="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    placeholder="Email"
                                    required
                                />
                            </div>
                            <div className="mb-3">
                                <input
                                    className="form-control"
                                    type="password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    placeholder="Password"
                                    required
                                />
                            </div>
                            {isLoginMode ? null : ( // Render password confirmation field for signup
                                <div className="mb-3">
                                    <input
                                        className="form-control"
                                        type="password"
                                        value={passwordConfirmation}
                                        onChange={(e) => setPasswordConfirmation(e.target.value)}
                                        placeholder="Confirm Password"
                                        required
                                    />
                                </div>
                            )}
                            <button type="submit" className="btn btn-primary w-100">
                                {isLoginMode ? 'Sign-in' : 'Sign-up'}
                            </button>
                            <div className="form-check form-switch mb-3">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    role="switch"
                                    id="loginSignupSwitch"
                                    checked={!isLoginMode}
                                    onChange={toggleMode}
                                />
                                <label className="form-check-label" htmlFor="loginSignupSwitch">
                                    <span className="text-primary">
                                        {isLoginMode ? 'Switch to Sign-up' : 'Switch to Sign-in'}
                                    </span>
                                </label>
                            </div>

                        </form>
                    </div>
                </Container>
            </Modal.Body>
        </Modal>
    );
});

export default LoginForm;
