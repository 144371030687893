import React, { useRef, useEffect } from "react";
import Image from "react-bootstrap/Image";
import GraphComponent from "./chart/GraphComponent";
import MovementComponent from "./movement/MovementComponent";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { observer } from "mobx-react";
import {WalletContext} from '../providers/walletProvider';
import {AuthContext} from "../providers/authProvider";


const MainArea = observer(() => {
    
    const walletContext = React.useContext(WalletContext);
    const authContext = React.useContext(AuthContext);

    const accessToken = useRef(null);

    useEffect(() => {
        const fetchAllData = async () => {
            try {
                accessToken.current = authContext.token;
                walletContext.token = accessToken.current;
                await walletContext.fetchAllPlans();
                await walletContext.fetchAllAccounts();
                await walletContext.fetchAllMovements();
            } catch (error) {
                console.log("something didn't work", error);
            }
        };
        
        if (authContext.token){
            fetchAllData();
        }
        
    }, [authContext.token, walletContext]);

    const loggedInUser = (
        <>
            <Container fluid="md">
                <Row>
                    <Col>
                        <MovementComponent />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <GraphComponent />
                    </Col>
                </Row>
            </Container>
        </>
    );

    return authContext.token ? loggedInUser : <Image src="wallet.png" fluid />;

});

export default MainArea;
