const shouldDisplayElipses = (text, maxLength) => {
    if (text.length <= maxLength) {
        return false;
    }
    return true;
}

const displayTextWithElipses = (text, maxLength) => {
    if (!shouldDisplayElipses(text, maxLength)) {
        return text;
    }
    return text.substring(0, maxLength) + "...";
}


const trimText = (text) => {
    return text.trim();
}

export { shouldDisplayElipses, displayTextWithElipses, trimText };