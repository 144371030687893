import { observer } from 'mobx-react';
import React from 'react';
import { Row, Col, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FaTrash, FaEdit } from 'react-icons/fa';
import { shouldDisplayElipses, displayTextWithElipses } from "../utils";

const RowPlan = observer(({ element, edit_cb, remove_cb }) => {
    return (
        <Row>
            <OverlayTrigger
                overlay={
                    shouldDisplayElipses(element.Category, 10) ?
                    <Tooltip id={`tooltip-${element.Category}`} className="preserve-spaces">
                        {element.Category}
                    </Tooltip> : <></>
                }
            >
                <Col xs={3} className="preserve-spaces">{displayTextWithElipses(element.Category, 10)}</Col>
            </OverlayTrigger>
            <Col xs={3}>{element.Amount}</Col>
            <Col xs={2}>{element.StartDate.split('T')[0]}</Col>
            <Col xs={2}>{element.EndDate.split('T')[0]}</Col>
            <Col xs={2}>
                <Button style={{ width: '50%' }} onClick={edit_cb} variant="warning"><FaEdit /></Button>
                <Button style={{ width: '50%' }} onClick={remove_cb} variant="danger"><FaTrash /></Button>
            </Col>
        </Row>
    );
});

export default RowPlan;
