import { observer } from "mobx-react";
import React, { useState } from 'react';
import { shouldDisplayElipses, displayTextWithElipses } from "../utils";
import { Row, Col, Container, OverlayTrigger, Tooltip } from "react-bootstrap";

const FilterCheckBox = observer(({ name, onChanged }) => {
    const [isChecked, setIsChecked] = useState(true);
    return (
        <Container>
            <Row>
                <Col xs={12}>
                    <input
                        type="checkbox"
                        checked={isChecked}
                        onChange={() => {
                            setIsChecked(!isChecked);
                            onChanged(!isChecked);
                        }}
                    />
                    <OverlayTrigger
                        overlay={
                            shouldDisplayElipses(name, 40) ?
                                <Tooltip id={`tooltip-category-${name}`} className="preserve-spaces">
                                    {name}
                                </Tooltip> : <></>
                        }
                    >
                        <span className="preserve-spaces">  {displayTextWithElipses(name, 40)}</span>
                    </OverlayTrigger>
                </Col>
            </Row>
        </Container>
    );
});

export default FilterCheckBox;