import { observer } from "mobx-react";
import { ResponsiveLine } from '@nivo/line'
import React from 'react';

const commonProperties = {
    width: 900,
    height: 400,
    margin: { top: 20, right: 20, bottom: 60, left: 80 },
    animate: true,
    enableSlices: 'x',
};

const SpendingChart = observer(({ data }) => {
    return (
        <ResponsiveLine 
            {...commonProperties}
            data={data}
            indexBy="x"
            keys={["id"]}
            xScale={{
                type: 'time',
                format: '%Y-%m-%d',
                useUTC: false,
                precision: 'day',
            }}
            yScale={{
                type: 'linear',
                stacked: false,
            }}
            axisLeft={{
                legend: 'spending',
                legendOffset: 12,
            }}
            axisBottom={{
                format: '%b %d',
                tickValues: 5,
                legendOffset: -12,
            }}
            curve={'monotoneX'}
            legends={[
                {
                    anchor: 'bottom-right',
                    direction: 'column',
                    justify: false,
                    translateX: 100,
                    translateY: 0,
                    itemsSpacing: 0,
                    itemDirection: 'left-to-right',
                    itemWidth: 80,
                    itemHeight: 20,
                    itemOpacity: 0.75,
                    symbolSize: 12,
                    symbolShape: 'circle',
                    symbolBorderColor: 'rgba(0, 0, 0, .5)',
                    effects: [
                        {
                            on: 'hover',
                            style: {
                                itemBackground: 'rgba(0, 0, 0, .03)',
                                itemOpacity: 1
                            }
                        }
                    ]
                }
            ]}
        />
    );
});

export default SpendingChart;