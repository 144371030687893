import React, {useState} from "react";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import LoginButton from "./LoginButton";
import LogoutButton from "./LogoutButton";
import AccountComponent from "./account/AccountComponent";
import PlanComponent from "./plan/PlanComponent";
import {AuthContext} from "../providers/authProvider";

import { observer } from "mobx-react";

const NavigationBar =  observer(() => {
    const [showAccounts, setShowAccounts] = useState(false);
    const [showPlans, setShowPlans] = useState(false);
    const authContext = React.useContext(AuthContext);
    
    const handleShowAccounts = () => {
        console.log('ShowAccounts clicked');
        setShowAccounts(true);
    };

    const handleShowPlans = () => {
        console.log('ShowPlans clicked');
        setShowPlans(true);
    };
    const loggedInUser = (
        <div className="d-flex justify-content-between w-100">
            <Nav>
                <NavDropdown title="Modify" id="basic-nav-dropdown">
                    <NavDropdown.Item onClick={handleShowAccounts}>
                        Accounts
                    </NavDropdown.Item>
                    <NavDropdown.Item onClick={handleShowPlans}>
                        Plans
                    </NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item href="#action/3.4">
                        Help
                    </NavDropdown.Item>
                </NavDropdown>
            </Nav>
            <Nav>
                <LogoutButton />
            </Nav>
            {/* Other components */}
            {showAccounts && <AccountComponent show={showAccounts} onHideClicked={() => setShowAccounts(false)} />}
            {showPlans && <PlanComponent show={showPlans} onHideClicked={() => setShowPlans(false)} />}
        </div>
    );

    const anonymousUser = (
        
            <Nav className="d-flex">
                <LoginButton />
            </Nav>
        
    );

    return (
        <>
            <Navbar bg="dark" variant="dark">
                <div className="container-fluid">
                    
                        <Navbar.Brand href="#home">Wallet</Navbar.Brand>

                        {authContext.token ? loggedInUser : anonymousUser}
                    
                </div>
            </Navbar>
        </>
    );
    
});

export default NavigationBar;
