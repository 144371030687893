import React, { useState } from "react";
import { observer } from "mobx-react";
import { WalletContext } from "../../providers/walletProvider";
import Modal from "react-bootstrap/Modal";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import { Container, Row, Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { displayTextWithElipses, trimText } from "../utils";

const EditMovement = observer(({ originalMovementId, onHideClicked }) => {
    const walletContext = React.useContext(WalletContext);
    const movementInContext = walletContext.movements.find(
        (e) => e.Id === originalMovementId
    );
    const [movement, setMovement] = useState(
        movementInContext !== undefined
            ? movementInContext
            : {
                AccountID: "",
                Amount: '',
                Description: "",
                Category: "",
                Date: new Date().toISOString(),
            }
    );

    const [movementDescriptionError, setMovementDescriptionError] = useState(false);

    const validateMovementDescription = (movementDescription) => {
        if (movementDescription === "") {
            return false;
        }
        return true;
    };

    const callback =
        movementInContext !== undefined
            ? walletContext.updateMovement
            : walletContext.addMovement;

    const plans = walletContext.plans.map((plan) => {
        return { value: plan.Id, label: displayTextWithElipses(plan.Category, 30) };
    });

    const accounts = walletContext.accounts.map((account) => {
        return { value: account.Id, label: displayTextWithElipses(account.Name, 30) };
    });

    const [movementAccountError, setMovementAccountError] = useState(false);
    const validateMovementAccount = (movementAccount) => {
        return accounts.some(account => account.value === movementAccount);
    };

    const [movementPlanError, setMovementPlanError] = useState(false);
    const validateMovementPlan = (movementPlan) => {
        return plans.some(plan => plan.value === movementPlan);
    }

    const [amountError, setAmountError] = useState(false);
    const validateAmount = (amount) => {
        if (amount <= 0 || amount > 1000000000) {
            return false;
        }
        return true;
    }

    return (
        <Modal show={true} size="lg">
            <Modal.Header closeButton onHide={() => onHideClicked()}>
                <Modal.Title>Money movement</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container fluid="md">
                    <Row>
                        <Col xs={6}>Transaction date</Col>
                        <Col xs={6}>
                            <input
                                style={{ width: '100%' }}
                                type="date" // Use input type "date"
                                value={movement.Date.split("T")[0]} // Format the date string
                                onChange={(e) => {
                                    setMovement({
                                        ...movement,
                                        Date: e.target.value + "T00:00:00.000Z", // Format the date to ISO format
                                    });
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={6}>Transaction amount</Col>
                        <Col xs={6}>
                            <input
                                style={{ width: '100%' }}
                                className={amountError ? "input-error" : ""}
                                placeholder="Amount"
                                value={movement.Amount}
                                onFocus={(e) => {
                                    let amount = isNaN(parseInt(movement.Amount)) ? 0 : parseInt(movement.Amount);
                                    if (!validateAmount(amount)) {
                                        setMovement({
                                            ...movement,
                                            Amount: "",
                                        });
                                    }
                                    setAmountError(false);
                                }}
                                onChange={(e) => {
                                    setAmountError(false);
                                    setMovement({
                                        ...movement,
                                        Amount: e.target.value
                                    })
                                }
                                }
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={6}>Transaction description</Col>
                        <Col xs={6}>
                            <input
                                style={{ width: '100%' }}
                                className={movementDescriptionError ? "input-error" : ""}
                                placeholder="Description"
                                value={movement.Description}
                                maxLength={50}
                                onFocus={(e) => {
                                    setMovementDescriptionError(false);
                                }}
                                onChange={(e) => {
                                    setMovementDescriptionError(false);
                                    setMovement({
                                        ...movement,
                                        Description: e.target.value,
                                    });
                                }
                                }
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={6}>Transaction account</Col>
                        <Col xs={6}>
                            <Dropdown
                                className={`input-dropdown ${movementAccountError ? "dropdown-error" : ""}`}
                                options={accounts}
                                value={movement.AccountID}
                                onFocus={(e) => {
                                    setMovementAccountError(false);
                                }}
                                onChange={(chosen) => {
                                    setMovementAccountError(false);
                                    setMovement({
                                        ...movement,
                                        AccountID: chosen.value,
                                    });
                                }
                                }
                                placeholder="Select an account"
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={6}>Transaction plan</Col>
                        <Col xs={6}>
                            <Dropdown
                                className={`input-dropdown ${movementPlanError ? "dropdown-error" : ""}`}
                                options={plans}
                                value={movement.Category}
                                onFocus={(e) => {
                                    setMovementPlanError(false);
                                }}
                                onChange={(chosen) => {
                                    setMovementPlanError(false);
                                    setMovement({
                                        ...movement,
                                        Category: chosen.value,
                                    });
                                }
                                }
                                placeholder="Select a plan"
                            />
                        </Col>
                    </Row>
                    <div className="row">
                        <div className="col"></div>
                        <div className="col">
                            <Button
                                type="button"
                                onClick={() => {
                                    let hasErrors = false;

                                    const trimmedMovementDescription = trimText(movement.Description);

                                    if (
                                        !validateMovementDescription(
                                            trimmedMovementDescription
                                        )
                                    ) {
                                        setMovementDescriptionError(true);
                                        hasErrors = true;
                                    } else {
                                        setMovementDescriptionError(false);
                                    }
                                    

                                    if (
                                        !validateMovementAccount(movement.AccountID)
                                    ) {
                                        console.log(`Account ${movement.AccountID} not found`);
                                        setMovementAccountError(true);
                                        hasErrors = true;
                                    } else {
                                        setMovementAccountError(false);
                                    }

                                    if (
                                        !validateMovementPlan(movement.Category)
                                    ) {
                                        console.log(`Plan ${movement.Category} not found`);
                                        setMovementPlanError(true);
                                        hasErrors = true;
                                    }

                                    let amount = isNaN(parseInt(movement.Amount)) ? 0 : parseInt(movement.Amount);

                                    if (!validateAmount(amount)) {
                                        movement.Amount = 'from 1 to 1000000000';
                                        movement.Description = "";
                                        setAmountError(true);
                                        hasErrors = true;
                                    } else {
                                        setAmountError(false);
                                    }

                                    if (hasErrors) {
                                        setMovement( {...movement, Description: trimmedMovementDescription});
                                        return;
                                    }
                                    
                                    movement.Amount = amount;
                                    movement.Description = trimmedMovementDescription;
                                    callback(movement);
                                    onHideClicked();
                                }}
                            >
                                Submit value
                            </Button>
                        </div>
                    </div>
                </Container>
            </Modal.Body>
        </Modal>
    );
});

export default EditMovement;
