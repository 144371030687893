import axios from "axios";

let hostApi = window.REACT_APP_HOST_API;

console.log(`hostApi: ${hostApi}`);

export default axios.create({
    baseURL: `${hostApi}/api`,
});

