import React, { useState } from "react";
import NewAccount from "./NewAccount";
import {WalletContext} from '../../providers/walletProvider';
import { observer } from "mobx-react";
import Modal from "react-bootstrap/Modal";
import { Container } from "react-bootstrap";
import RowAccount from './RowAccount';



const AccountComponent = observer(({ show, onHideClicked }) =>  {
    const walletContext = React.useContext(WalletContext);
    const [accountToEdit, setAccountToEdit] = useState(null);

    return (
        <Modal show={show} size='lg' scrollable>
            
            <Modal.Header closeButton onHide={() => {onHideClicked();}}>
                <Modal.Title>Accounts</Modal.Title>
            </Modal.Header>

            <Modal.Body style={{width: "100%"}}>   
                <Container>
                    {walletContext.accounts.map((element) => {
                        return accountToEdit && element.Id === accountToEdit ? 
                            <NewAccount 
                                key={element.Id} 
                                element={element} 
                                cb={(account) => {
                                    walletContext.updateAccount(account);
                                    setAccountToEdit(null);
                                }}/> : 
                            <RowAccount 
                                key={element.Id}
                                element={element} 
                                edit_cb={() => {
                                    setAccountToEdit(element.Id);
                                }} 
                                remove_cb={() => {
                                    walletContext.removeAccount(element.Id);
                                }} />;
                    })}
                    <NewAccount 
                        key="not-set-yet"
                        element={null} 
                        cb={(account) => walletContext.addAccount(account)}/>
                </Container>
                
            </Modal.Body>
            
        </Modal>
    );
});

export default AccountComponent;
