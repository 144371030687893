import React, { useState } from "react";
import { observer } from "mobx-react";
import Button from "react-bootstrap/Button";
import { Col, Row } from "react-bootstrap";
import { trimText } from "../utils";

const NewPlan = observer(({ onClickCallBack, elementToEdit }) => {
    const [newPlan, setNewPlan] = useState({
        Id: elementToEdit ? elementToEdit.Id : null,
        Category: elementToEdit ? elementToEdit.Category : "",
        Amount: elementToEdit ? elementToEdit.Amount : "",
        UserID: elementToEdit ? elementToEdit.UserID : "",
        EndDate: elementToEdit ? elementToEdit.EndDate : (new Date()).toISOString(),
        StartDate: elementToEdit ? elementToEdit.StartDate : (new Date()).toISOString()
    })

    const [categoryNameError, setCategoryNameError] = useState(false);
    const [amountError, setAmountError] = useState(false);

    const validateCategoryName = (categoryName) => {
        if (categoryName === "") {
            return false;
        }
        return true;
    }

    const validateAmount = (amount) => {
        if (amount <= 0 || amount > 1000000000) {
            return false;
        }
        return true;
    }
    

    return (
        <Row>
            <Col xs={3}>
                <input
                    style={{ width: '100%' }}
                    className={categoryNameError ? "input-error" : ""}
                    placeholder="Category name"
                    value={newPlan.Category}
                    maxLength={50}
                    onFocus={(e) => {
                        setCategoryNameError(false);
                    }}
                    onChange={(e) => {
                        setCategoryNameError(false);
                        setNewPlan({
                            ...newPlan,
                            Category: e.target.value
                        })
                    }}
                />
            </Col>
            <Col xs={3}>
                <input
                    style={{ width: '100%' }}
                    className={amountError ? "input-error" : ""}
                    placeholder="Amount"
                    value={newPlan.Amount}
                    onFocus={(e) => {
                        let amount = isNaN(parseInt(newPlan.Amount)) ? 0 : parseInt(newPlan.Amount);
                        if (!validateAmount(amount)) {
                            setNewPlan({
                                ...newPlan,
                                Amount: ""
                            });
                        }
                        setAmountError(false);
                    }}
                    onChange={(e) => {
                        setAmountError(false);
                        setNewPlan({
                            ...newPlan,
                            Amount: e.target.value
                        });
                    }}
                />
            </Col>
            <Col xs={2}>
                <input
                    style={{ width: '100%' }}
                    type="date" // Use input type "date"
                    placeholder="Start Date"
                    value={newPlan.StartDate.split('T')[0]} // Format the date string
                    onChange={(e) => {
                        setNewPlan({
                            ...newPlan,
                            StartDate: e.target.value + "T00:00:00.000Z" // Format the date to ISO format
                        });
                    }}
                />
            </Col>
            <Col xs={2}>
                <input
                    style={{ width: '100%' }}
                    type="date" // Use input type "date"
                    placeholder="End Date"
                    value={newPlan.EndDate.split('T')[0]} // Format the date string
                    onChange={(e) => {
                        setNewPlan({
                            ...newPlan,
                            EndDate: e.target.value + "T23:59:59.999Z" // Format the date to ISO format
                        });
                    }}
                />
            </Col>
            <Col xs={2}>
                <Button  style={{ width: '100%' }} type="button" onClick={() => {
                    let hasErrors = false;
                    const trimmedCategoryName = trimText(newPlan.Category);
                    if (!validateCategoryName(trimmedCategoryName)) {
                        setCategoryNameError(true);
                        hasErrors = true;
                    } else {
                        setCategoryNameError(false);
                    }

                    newPlan.Category = trimmedCategoryName;
                    
                    let amount = isNaN(parseInt(newPlan.Amount)) ? 0 : parseInt(newPlan.Amount);
                    if (!validateAmount(amount)) {
                        newPlan.Amount = 'from 1 to 1000000000';
                        setAmountError(true);
                        hasErrors = true;
                    }
                    else {
                        setAmountError(false);
                    }

                    if (hasErrors) {
                        return;
                    }
                    
                    newPlan.Amount = amount;

                    onClickCallBack(newPlan);
                    setNewPlan({
                        Category: "",
                        Amount: "",
                        EndDate: (new Date()).toISOString(),
                        StartDate: (new Date()).toISOString()
                    });
                }}>Submit</Button>
            </Col>
        </Row>
    );
});

export default NewPlan;