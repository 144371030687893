import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { observer } from 'mobx-react';
import { WalletContext } from '../../providers/walletProvider';
import { Container, Col, Row, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FaTrash, FaEdit } from 'react-icons/fa';
import EditMovement from './EditMovementComponent';
import { shouldDisplayElipses, displayTextWithElipses } from "../utils";

const MovementComponent = observer(() => {
    const walletContext = React.useContext(WalletContext);
    const [movementToEdit, setMovementToEdit] = useState(null);

    return (
        <>
            <Button
                onClick={() => {
                    setMovementToEdit('-1');
                }
                }>
                New movement
            </Button>
            <Container>
                {
                    walletContext.movements.map((element) => {
                        return (
                            <Row key={element.Id}>
                                <Col xs={2}>
                                    {element.Date.split('T')[0]}
                                </Col>
                                <OverlayTrigger
                                    overlay={
                                        shouldDisplayElipses(walletContext.getAccountName(element.AccountID), 20) || shouldDisplayElipses(walletContext.getPlanName(element.Category), 20) ?
                                            <Tooltip id={`tooltip-${element.AccountID}-${element.Category}`} className="preserve-spaces">
                                                {walletContext.getAccountName(element.AccountID)}/{walletContext.getPlanName(element.Category)}
                                            </Tooltip> : <></>
                                    }
                                >
                                    <Col  xs={4} className="preserve-spaces">
                                        {displayTextWithElipses(walletContext.getAccountName(element.AccountID), 20)}/{displayTextWithElipses(walletContext.getPlanName(element.Category), 20)}
                                    </Col>
                                </OverlayTrigger>
                                <Col  xs={2}>
                                    {element.Amount}
                                </Col>
                                <OverlayTrigger
                                    overlay={
                                        shouldDisplayElipses(element.Description, 30) ?
                                            <Tooltip id={`tooltip-${element.Description}`} className="preserve-spaces">
                                                {element.Description}
                                            </Tooltip> : <></>
                                    }
                                >
                                    <Col  xs={3} className="preserve-spaces">
                                        {displayTextWithElipses(element.Description, 30)}
                                    </Col>
                                </OverlayTrigger>
                                <Col  xs={1}>
                                    <div style={{ float: "right" }}>
                                        <Button onClick={() => { setMovementToEdit(element.Id); }} variant="warning">
                                            <FaEdit />
                                        </Button>
                                        <Button onClick={() => walletContext.removeMovement(element.Id)} variant="danger">
                                            <FaTrash />
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                        );
                    })
                }
            </Container>

            {movementToEdit ?
                <EditMovement
                    originalMovementId={movementToEdit}
                    onHideClicked={() => setMovementToEdit(null)} /> :
                null
            }

        </>
    );
});

export default MovementComponent;
