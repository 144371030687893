import React from "react";
import axios from 'axios';
import {useLocalObservable} from 'mobx-react';
import {runInAction} from 'mobx';
import {jwtDecode} from "jwt-decode";

let hostApi = window.REACT_APP_HOST_API;

export const AuthContext = React.createContext(null);

const ServerAuthProvider = ({ children }) => {
    const auth = useLocalObservable(()=>({
        token: null,
        timeoutId: null,

        onLoginSuccess: (token) => {
            runInAction(() => {
                auth.token = token;
                auth.handleTokenChange(auth.token);
            })
        },

        onLoginError: (error) => {
            console.error(error);
            runInAction(() => {
                auth.token = null;
                auth.handleTokenChange(auth.token);
            })
        },

        logout: () => {
            // Logic to log out the user
            runInAction(() => {
                auth.token = null;
                auth.handleTokenChange(auth.token);
            })
        },

        handleSubmit: async (email, password, isLoginMode) => {
            let responseReturn = true;
            const url = {
                'login':    `${hostApi}/api/user/signin`,
                'signup': `${hostApi}/api/user/signup`
            };
        
            try {
                console.warn(`Request to ${isLoginMode ? url['login'] : url['signup']}`);
                let response = await axios.post(isLoginMode ? url['login'] : url['signup'], { email, password });
                if (!isLoginMode) {
                    response = await axios.post(url['login'], { email, password });
                }
                console.warn(`Response with ${response.data.token}`);
                auth.onLoginSuccess(response.data.token); 
            } catch (error) {
                auth.onLoginError(error);
                if (error.response.status === 401) {
                    console.error('401');
                    responseReturn = false;
                }
            }
            return responseReturn;
        },

        refreshAuthToken: async () => {
            // Logic to refresh the token
            // For now, let's just log a message
            console.log("Refreshing token...");
            try {
                let response = await axios.post(`${hostApi}/api/user/refresh`, {},
                {
                  headers: {
                    Authorization: `Bearer ${auth.token}`, 
                  },
                });
                
                auth.onLoginSuccess(response.data.token);
            } catch (error) {
                auth.onLoginError(error);
            }
        },

        handleTokenChange: (newToken) => {
            if (auth.timeoutId) {
                clearTimeout(auth.timeoutId);
            }

            if (newToken == null) {
                console.log('removing token');
                localStorage.removeItem("token");
                return;
            }
            localStorage.setItem("token", newToken);

            const decodedToken = jwtDecode(newToken);
            const expirationTime = decodedToken.exp; // Assuming JWT with exp in Unix time
            const currentTime = Date.now() / 1000; // Current time in Unix time
            const duration = expirationTime - currentTime;
            const refreshInterval = duration / 2; // Refresh at half the token's lifespan

            console.log(`Token duration: ${duration}, refresh interval ${refreshInterval}`);

            auth.timeoutId = setTimeout(auth.refreshAuthToken, refreshInterval * 1000); // Convert to milliseconds   
        }
    }));

    auth.token = localStorage.getItem("token");
    auth.handleTokenChange(auth.token);

    return (
        <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>
    );
  }

export default ServerAuthProvider;