import { observer } from "mobx-react";
import React, { useContext, useState } from "react";
import SpendingChart from "./SpendingChart";
import {WalletContext} from '../../providers/walletProvider';
import FilterCheckBox from './FilterCheckBox';


const GraphComponent = observer(() => {

  const walletContext = useContext(WalletContext);
  const [categoriesToExclude, setCategoriesToExclude] = useState([]);

  const dataToShow = [];
  const categoryData = {};
  const categories = [];
  let indexMovements = 0;
  walletContext.movements.map((el) => {
    console.log(el.Date.split('T')[0], el.Date);
    indexMovements += 1;
    const dataToAdd = {
      x: el.Date.split('T')[0],
      y: el.Amount,
      label: el.Description,
      id: `${el.Category}-${indexMovements}` // Unique identifier
    };

    if (categoryData[el.Category] === undefined) {
      categoryData[el.Category] = [];
      categories.push(el.Category);
    }

    if (categoriesToExclude.findIndex((e) => e === el.Category) !== -1) {
      return null; // skip to process
    }
    categoryData[el.Category].push(dataToAdd);
    return null;
  });
  let index = 0;
  
  for (let key in categoryData) {
    index += 10;
    const hValue = index + 31;
    dataToShow.push({
      "id": key,
      "color": `hsl(${hValue}, 70%, 50%)`,
      "data": categoryData[key]
    });
  }
  // console.log(dataToShow);
  return (
    <>
      <div>Filter by category</div>
      {categories.map((key) => <FilterCheckBox key={key} name={walletContext.getPlanName(key)} onChanged={(checked) => {
        console.log(`${key} is ${checked}`);
        if (!checked) {
          setCategoriesToExclude([...categoriesToExclude, key]);
        } else {
          setCategoriesToExclude(categoriesToExclude.filter((el) => el !== key));
        }
      }}/>)}
      <div style={{height: '500px'}}>
        <SpendingChart  data={dataToShow} />
      </div>
    </>
  );
});

export default GraphComponent;
