import React, {useState} from "react";
import { observer } from "mobx-react";
import { WalletContext } from "../../providers/walletProvider";
import Modal from "react-bootstrap/Modal";
import NewPlan from "./NewPlan";
import { Container } from "react-bootstrap";
import RowPlan from "./RowPlan";

const PlanComponent = observer(({show, onHideClicked}) => {
    const walletContext = React.useContext(WalletContext);
    const [planToEdit, setPlanToEdit] = useState(null);

    return (
        <Modal show={show} size="lg">
            <Modal.Header closeButton onHide={() => {onHideClicked()}}>
                <Modal.Title>Plans</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container fluid="md">
                    {walletContext.plans.map((element) => {
                        return planToEdit && planToEdit === element.Id ? 
                            <NewPlan 
                                key={element.Id} 
                                elementToEdit={element}
                                onClickCallBack={(plan) => {
                                    walletContext.updatePlan(plan);
                                    setPlanToEdit(null);
                                }}
                            /> : 
                            <RowPlan 
                                key={element.Id} 
                                element={element}
                                edit_cb={() => {
                                    setPlanToEdit(element.Id);
                                }}
                                remove_cb={() => {
                                    walletContext.removePlan(element.Id);
                                }}
                            />;
                    })}
                    <NewPlan  
                        key="not-set-yet"
                        onClickCallBack={(plan) => {
                        walletContext.addPlan(plan)
                    }}/>
                </Container>
                
            </Modal.Body>
        </Modal>
    );
});

export default PlanComponent;