import React from 'react';
import { observer } from 'mobx-react';
import { Row, Col, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FaTrash, FaEdit } from 'react-icons/fa';
import { shouldDisplayElipses, displayTextWithElipses } from "../utils";


const RowAccount = observer(({ element, edit_cb, remove_cb }) => {
    return (
        <Row>
            <OverlayTrigger
                overlay={
                    shouldDisplayElipses(element.Name, 30) ?
                        <Tooltip id={`tooltip-${element.Name}`} className="preserve-spaces">
                            {element.Name}
                        </Tooltip> : <></>
                }
            >
                <Col xs={5} className="preserve-spaces">{displayTextWithElipses(element.Name, 30)}</Col>
            </OverlayTrigger>
            <OverlayTrigger
                overlay={
                    shouldDisplayElipses(element.Information, 30) ?
                        <Tooltip id={`tooltip-${element.Information}`} className="preserve-spaces">
                            {element.Information}
                        </Tooltip> : <></>
                }
            >
                <Col xs={5} className="preserve-spaces">{displayTextWithElipses(element.Information, 30)}</Col>
            </OverlayTrigger>
            <Col xs={2}>
                <Button onClick={edit_cb} variant="warning">
                    <FaEdit />
                </Button>
                <Button onClick={remove_cb} variant="danger">
                    <FaTrash />
                </Button>
            </Col>
        </Row>
    );
});

export default RowAccount;