import React, { useState } from "react";
import {Button, Row, Col} from "react-bootstrap";
import { trimText } from "../utils";


const NewAccount = ({element, cb}) => {
    
    const [account, setAccount] = useState({
        Id: element ? element.Id : null,
        Information: element ? element.Information : "",
        Name: element ? element.Name : "",
        UserID: element ? element.UserID : ""
    });

    const [accountInformationError, setAccountInformationError] = useState(false);
    const [accountNameError, setAccountNameError] = useState(false);

    const validateAccountName = (accountName) => {
        if (accountName === "") {
            return false;
        }
        return true;
    }

    const validateAccountInformation = (accountInformation) => {
        if (accountInformation === "") {
            return false;
        }
        return true;
    }

    
    return (
        <Row>
            <Col xs={5}>
                <input 
                    className={accountNameError ? "input-error" : ""}
                    value={account.Name} 
                    onChange={(e) => {
                        setAccountNameError(false);
                        setAccount( {...account, Name: e.target.value});
                    }}
                    onFocus={(e) => {
                        setAccountNameError(false);
                    }}
                    placeholder="Account name"
                    maxLength={50}
                    style={{width: '100%'}}
                />
            </Col>
            <Col xs={5}>
                <input
                    className={accountInformationError ? "input-error" : ""}
                    value={account.Information}
                    onChange={(e) => {
                        setAccountInformationError(false);
                        setAccount({...account, Information: e.target.value});
                    }}
                    onFocus={(e) => {
                        setAccountInformationError(false);
                    }}
                    placeholder="Account information"
                    maxLength={50}
                    style={{width: '100%'}}
                />
            </Col>
            <Col xs={2}>
                <Button type="button" onClick={() => {

                     let hasErrors = false;

                    const trimmedAccountInformation = trimText(account.Information);
                    if (!validateAccountInformation(trimmedAccountInformation)) {
                        setAccountInformationError(true);
                        hasErrors = true;
                    } else {
                        setAccountInformationError(false);  
                    }

                    const trimmedAccountName = trimText(account.Name);

                    if (!validateAccountName(trimmedAccountName)) {
                        setAccountNameError(true);
                        hasErrors = true;
                    } else {
                        setAccountNameError(false);
                    }

                    if (hasErrors) {
                        setAccount( {Information: trimmedAccountInformation, Name: trimmedAccountName});
                        return;
                    }

                    account.Name = trimmedAccountName;
                    account.Information = trimmedAccountInformation;

                    cb(account);
                    // reset state
                    setAccount({Information: "", Name: ""});
                    }}>
                    Submit
                </Button>
            </Col>
        </Row>
    );
    
    
}

export default NewAccount;
